<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate">Reseller - Edit</span>
        </span>
        <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
          <i class="fa fa-home text-main"></i>
        </a>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row mt-4 mb-5">
          <div class="col-12">
            <Notif />
            <Form
              @submit="update"
              :validation-schema="schema"
              v-slot="{ errors }"
              method="POST"
              class="mb-3 needs-validation"
            >
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="nama"
                      >Nama Reseller: <span class="text-danger">*</span></label
                    >
                    <Field
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.name }"
                      id="name"
                      placeholder="Masukkan nama..."
                      name="name"
                      v-model="state.form.name"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="sel1"
                      >Status:<span class="text-danger ml-1">*</span></label
                    >
                    <Field name="status" as="select" class="form-control" id="status" :class="{ 'is-invalid': errors.status }" v-model="state.form.status">
                      <option :value="1" selected>Aktif</option>
                      <option :value="0">Inaktif</option>
                    </Field>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.status }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="telp"
                      >Telepon: <span class="text-danger">*</span></label
                    >
                    <vue-tel-input :value="state.form.phone"  v-model="state.form.phone" @input="phoneInput" class="form-control" id="phone" name="phone" :class="{ 'is-invalid': errors.phone }"></vue-tel-input>

                    <Field
                      type="hidden"
                      name="phone"
                      v-model="state.form.phone"
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.phone }}</div>
                  </div>
                </div>
                <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="addr"
                      >Alamat: <span class="text-danger">*</span></label
                    >
                    <Field type="hidden" name="alamat" v-model="state.form.alamat" />
                    <textarea
                      class="form-control"
                      :class="{ 'is-invalid': errors.alamat }"
                      rows="2"
                      id="alamat"
                      name="alamat"
                      v-model="state.form.alamat"
                      placeholder="Masukkan alamat..."
                    ></textarea>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">{{ errors.alamat }}</div>
                  </div>
                </div>
                <!-- .col-* -->
              </div>
              <!-- .form-row -->

              <hr class="no-side-margin" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button
                    type="submit"
                    class="btn btn-main"
                    title="Simpan"
                    :disabled="state.loading"
                  >
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                    <i class="fas fa-save mr-2" v-else></i>Simpan
                  </button>
                  <button
                    type="reset"
                    class="btn btn-light ml-1"
                    title="Reset isian"
                  >
                    <i class="fas fa-sync-alt mr-2"></i>Reset
                  </button>
                  <a
                    href="#"
                    class="btn btn-light ml-1"
                    title="Batal"
                    @click="batal"
                    ><i class="fas fa-times mr-2"></i>Batal</a
                  >
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->
            </Form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import Notif from "../../../helpers/Notif.vue";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      id: "",
      form: {
        name: "",
        status: "",
        phone: "",
        alamat: "",
      },
      loading: false,
    });

    const loadData = async () => {
      let id = route.params.id;
      await axios.get("/api/seller/reseller/" + id).then((res) => {
        if (res.data.success) {
          state.form.name = res.data.data.name;
          state.form.alamat = res.data.data.alamat;
          state.form.phone = res.data.data.phone;
          state.form.status = res.data.data.status;
        }
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      store.dispatch("error/setDefault");
      loadData();
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/pelanggan/reseller/info/" + state.id });
          }
        });
    };
    //start validate
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      status: Yup.string().required("Pilih Salah satu"),
      alamat: Yup.string().required("Alamat tidak boleh kosong"),
      phone: Yup.string().required("Phone tidak boleh kosong"),
    });
    const update = () => {
      state.loading = true;
      let id = route.params.id;
      axios.put("/api/seller/reseller/" + id, state.form).then(
        (res) => {
          if (res.data.success) {
            state.loading = false;
            //notif
            const notif = [];
            notif.push("Update data Berhasil!");
            store.dispatch("error/setSuccess", notif);
            loadData();
          }
        },
        () => {
          state.loading = false;
        }
      );
    };
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
      
    }
    return {
      state,
      batal,
      schema,
      update,
      back
    };
  },
  components: {
    Form,
    Field,
    Notif,
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject?.formatted) {
        this.state.form.phone = phoneObject.formatted;
      }
    },
  },
};
</script>